import React,{useEffect} from 'react'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import { Layout, Menu } from "antd"
import ExploreHeader from '@src/components/layout/ExploreHeader'
import GalleryHeader from '@src/components/layout/GalleryHeader'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import MePanel from '@src/components/setting/MePanel'
import PropertyDetailPageContent from '@src/components/property/PropertyDetailPageContent'
import PropertyGallery from '@src/components/property/PropertyGallery'
import Notifier from '@src/components/Notifier'
import LandingPageOptions from '@src/components/LandingPageOptions'
import { Router, Link } from "@reach/router"
import find from 'lodash/find'
import withRoot from '@src/withRoot'

const { Header, Footer, Content } = Layout

const ImageGallery = compose(
    inject('propertyStore','generalPropertyStore','uiStore'),
    observer
)(props=>{
    const {
        propertyStore,
        planId
    } = props
    const {currentDetails} = propertyStore
    let data = currentDetails
    if(planId){
        if(currentDetails.processedData.isProject && currentDetails.processedData.units.length>0){
            const plan = find(currentDetails.processedData.units,o=>o.rawData.id==planId)
            if(plan){
                data = plan
            }
        }
    }
    return <Layout style={{backgroundColor:'white',maxWidth:1440,margin: "0 auto"}}>
        <Notifier />
        <GalleryHeader data={data}/>
        <Content>
            <PropertyGallery data={data}/>
        </Content>
        <div style={{
            margin:32,
            marginLeft:64,
            marginRight:64
        }}>
            <LandingPageOptions/>
        </div>
    </Layout>
})

export default withRoot(ImageGallery)