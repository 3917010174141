import React,{useEffect} from 'react'
import { Layout, Menu } from "antd"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link ,navigate} from "gatsby"
import { hBox,vBox } from "gx-web-ui/utils/flex";
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase'
import Button from '@material-ui/core/Button'
import { ReactComponent as Account } from '@src/assets/svgIcons/Account.svg'
import LoginModal from '@src/components/LoginModal'
import Avatar from '@material-ui/core/Avatar';
import trim from 'lodash/trim'
import LoginIconButton from '../common/LoginIconButton'
import TEAMLINKBlackLogo from '../../assets/icons/TEAMLINK-logo.png'

import {ReactComponent as ThreeD} from '@src/assets/svgIcons/3D.svg'
import {ReactComponent as Play} from '@src/assets/svgIcons/Play.svg'
import {ReactComponent as Cross} from '@src/assets/svgIcons/Cross.svg'
import FloorPlan from '@src/components/icons/FloorPlan'
import { typography } from '../../theme/theme';
import findIndex from 'lodash/findIndex'

const { Header } = Layout

const GalleryHeader = (props) => {
    const {data} = props
    const {services,address,banners} = data.processedData
    const bottomRightActions = [
        {
            key:'floorPlan',
            Icon:FloorPlan,
            onClick:()=>window.location.href ='#floorPlan',
            disabled:!Boolean(services.floorPlan),
        },
        {
            key:'play',
            Icon:Play,
            onClick:()=> window.location.href ='#video',
            disabled:!Boolean(findIndex(banners,o=>Boolean(o.video))>0),
        },
        {
            key:'3d',
            Icon:ThreeD,
            onClick:()=>window.open(services.VR,"blank"),
            disabled:!Boolean(services.VR),
        },
    ]
    const styles = useStyles()
    return (
        <Header 
        style={{
                zIndex:10,
                width: "100%",
                height:64,
                backgroundColor:"white",
                boxShadow:'0px 4px 10px 0px #eeeeee',
                ...hBox('center')
            }}>
            <div className={styles.container}> 
                <div className={styles.actions}>
                {bottomRightActions.map(({Icon,onClick,disabled,key})=>(
                    <ButtonBase key={key} disableRipple disabled={disabled} onClick={onClick}>
                        <Icon height={25} width={25} fill={disabled?'rgba(0,0,0,0.2)':'black'} className={styles.iconButton}/>
                    </ButtonBase>
                ))}
                </div>
                <div className={styles.title}>
                    {address.fullText}
                </div>
                <ButtonBase disableRipple onClick={()=>window.close()}>
                    <Cross height={20} width={20} fill={'black'} className={styles.iconButton}/>
                </ButtonBase>

            </div>
        </Header>
    )
}

const useStyles = makeStyles(theme=>({
    container:{
        ...hBox('center','space-between'),
        width:'100%'
    },
    root:{
        zIndex:10,
        width: "100%",
        height:64,
        backgroundColor:"white",
        boxShadow:'0px 4px 10px 0px #eeeeee'
    },
    actions:{
        ...hBox('center'),
    },
    iconButton:{
        marginLeft:12,
        marginRight:8,
    },
    title:{
        transform:"translateX(-25%)",
        fontWeight: 400,
        fontSize: 18,
        color:"black"
    }
}))

export default GalleryHeader
