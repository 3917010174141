import React from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import {
    isTablet,
    isMobile
} from "react-device-detect";

import findIndex from 'lodash/findIndex'
import ReactPlayer from 'react-player'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const PropertyGallery = (props) => {
    const {data} = props
    const styles = useStyles()
    const {banners,services} = data.processedData
    const images = data.processedData.banners.map(banner => ({ url: banner.url }));
    const floorPlanIndex = services.floorPlan? findIndex(images,{url:services.floorPlan}):-1
    const videoIndex = findIndex(banners,o=>Boolean(o.video))
    return (
        <div className={styles.root}>
            {/* {images.map(({url},index)=>
            <div key={index}>
                <img src={url} className={styles.img}/>
            </div>
            )} */}
            {banners.map((banner,index)=>
                banner.video?
                    <div id={videoIndex && videoIndex === index && "video"} key={index} className={styles.video}>
                        <ReactPlayer 
                        width="100%" 
                        height={'100%'} 
                        key={index} 
                        url={banner.video} 
                        controls
                        style={{backgroundColor:'black'}}
                        />
                    </div>
                :
                banner.placeholder?
                    <img src={banner.placeholder} key={index} className={styles.img} />
                :
                <div key={index}>
                    {(floorPlanIndex && floorPlanIndex===index)?
                        <img id={"floorPlan"} src={banner.url} className={styles.img} />
                    :
                    <img src={banner.url} className={styles.img} />
                    }
                </div>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    root:{
        margin:32,
        marginLeft:isMobile?16:64,
        marginRight:isMobile?16:64,
    },
    img:{
        borderRadius:5,

        width:'100%',
        height:'auto',
        objectFit:'cover',
        marginTop:16,
        marginBottom:16
    },
    video:{
        width:"100%",
        height:'70vh',
        borderRadius:5,
        overflow:'hidden',        
        marginTop:16,
        marginBottom:16,
    }
}))

export default PropertyGallery
