import React from 'react';
import compose from 'recompose/compose';
import { inject, observer } from 'mobx-react';

import PropertyDetailPageContent from '@src/components/property/PropertyDetailPageContent';
import withRoot from '@src/withRoot';

const PropertyDetailPage = (props) => {
  const { propertyStore, pageContextData } = props
  const { currentDetails: data } = propertyStore

  return (
    <PropertyDetailPageContent data={data} pageContextData={pageContextData} />
  )
};

export default compose(withRoot, inject('propertyStore', 'generalPropertyStore'), observer)(PropertyDetailPage)